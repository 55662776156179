<template>
  <div id="app">
    <LoginBg :pageTitle="$t('register.title')" :isShowRegister=false />
    
    <br />
    <v-layout justify-center>
      <!-- <v-form @submit.prevent="submit"> -->
      <v-form>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input inputNumber"
            v-model="username"
            type="number"
            autocomplete="username"
            :label="$t('register.username')"
            :placeholder="$t('register.username')"
            :disabled="isRegister"
            append-icon="phone_iphone"
            filled
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="tm_firstname"
            type="text"
            autocomplete="tm_firstname"
            :label="$t('register.firstname')"
            :placeholder="$t('register.firstname')"
            :disabled="isRegister"
            filled
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="tm_lastname"
            type="text"
            autocomplete="tm_lastname"
            :label="$t('register.lastname')"
            :placeholder="$t('register.lastname')"
            :disabled="isRegister"
            filled
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="email"
            type="text"
            autocomplete="email"
            :label="$t('register.email')"
            :placeholder="$t('register.email')"
            :disabled="isRegister"
            filled
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="password"
            :label="$t('register.password')"
            :placeholder="$t('register.password')"
            :append-icon="isShowPassword1 ? 'visibility' : 'visibility_off'"
            @click:append="isShowPassword1 = !isShowPassword1"
            :type="isShowPassword1 ? 'text' : 'password'"
            :disabled="isRegister"
            autocomplete="password"
            filled
            hide-details
            clearable
          />
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="confirmPassword"
            :label="$t('register.confirm_password')"
            :placeholder="$t('register.confirm_password')"
            :append-icon="isShowPassword2 ? 'visibility' : 'visibility_off'"
            @click:append="isShowPassword2 = !isShowPassword2"
            :type="isShowPassword2 ? 'text' : 'password'"
            :disabled="isRegister"
            autocomplete="confirmPassword"
            filled
            hide-details
            clearable
          />
        </div>
        <div class="mb-5" style="width: 300px">
          <v-row>
            <v-col cols="7">
              <v-text-field
                class="text_input inputNumber"
                v-model="otp"
                type="number"
                autocomplete="otp"
                :label="$t('register.otp_code')"
                :placeholder="$t('register.otp_code')"
                :disabled="!isRegister"
                filled
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-btn class="mt-1" color="#1976D2" width="110" height="50" dark @click="requestOtpClick"><div v-html="$t('register.request_otp')"></div></v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-layout>

    <v-layout justify-center>
      <span class="link--text" style="width: 300px; display:inline-flex;">
        <input class="largerCheckbox mr-4" type="checkbox" v-model="isAccept"/>
        By continuing, you accept our Privacy Policy and agree to the 
        <a class="ml-5" href="/terms">Terms &amp; Condition</a>
      </span> 
    </v-layout>

    <br />
    <v-layout justify-center>
      <div style="width: 300px; text-align: center;">
        <v-btn class="mr-5" rounded width="100" @click="$router.back()">{{ $t('register.back_button') }}</v-btn>
        <v-btn rounded color="#3949AB darken-4" width="180" dark @click="registerClick" :disabled="!isAccept || !isRegister || !otp">{{ $t('register.register_button') }}</v-btn>
      </div>
    </v-layout>
    <br />

    <ShowOverlay 
      v-model="isShowOverlay">
    </ShowOverlay>
    <ShowMessage 
      v-model="isShowMessage" 
      :msg="showmessage_msg"
      :width="showmessage_width"
      :callBack="showmessage_callback">
    </ShowMessage>
  </div>
</template>

<script>
import LoginBg from "@/components/LoginBg"
import ShowOverlay from "@/components/ShowOverlay"
import ShowMessage from "@/components/ShowMessage"
import api from "@/services/api";

export default {
  name: "Register",
  data() {
    return {
      username: '',
      tm_firstname: '',
      tm_lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      otp: '',
      isShowPassword1: false,
      isShowPassword2: false,

      isAccept: false,
      isRegister: false,

      isShowOverlay: false,
      isShowMessage: false,
      showmessage_msg: "",
      showmessage_width: 400,
      showmessage_callback: null
    };
  },
  components: {
    LoginBg,
    ShowOverlay,
    ShowMessage
  },
  methods: {
    showMessage(showmessage_msg, showmessage_width) {
        this.showmessage_msg = showmessage_msg;
        this.showmessage_width = showmessage_width;
        this.isShowMessage = true;
    },
    onShowMessageCallBack() {
      this.$router.back();
    },
    async resendOtp() {
      try {
        // this.showmessage_callback = null;
        // this.isShowOverlay = true;
        const response = await api.resendOtp(this.username);
          // console.log(response);
          if (response.status==200) {
            this.isRegister = true;
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.send_otp_success}`, 400);
          } else {
            this.isRegister = false;
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.send_otp_notsuccess}`, 400);
          }
      } catch (error) {
        this.showMessage(error.response.data.status.toString().concat(' : ').concat(error.response.data.message), 400);
      }
      finally {
        // setTimeout(() => {
        //   this.isShowOverlay = false;
        // }, 1000);
      }
    },
    async requestOtpClick() {
      try {
        this.isRegister = false;
        
        this.showmessage_callback = null;
        if (this.username?.trim()=="" || this.username?.trim().length!=10) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_phoneno}`, 400);
          return;
        }
        if (this.tm_firstname?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_firstname}`, 400);
          return;
        }
        if (this.tm_lastname?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_lastname}`, 400);
          return;
        }
        if (this.password?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_password}`, 400);
          return;
        }
        if (this.confirmPassword?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_confirm_password}`, 400);
          return;
        }
        if (this.password?.trim()!=this.confirmPassword?.trim()) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.password_not_equal_confirm_password}`, 400);
          return;
        }

        this.isShowOverlay = true;
        const response = await api.register(
          this.username, 
          this.password, 
          this.confirmPassword, 
          this.tm_firstname, 
          this.tm_lastname, 
          this.email);
          // console.log(response);
          if (response.status==200) {
            this.isRegister = true;
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.send_otp_success}`, 400);
          } else {
            this.isRegister = false;
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.send_otp_notsuccess}`, 400);
          }
      } catch (error) {
        if (error.response.data.message.search("phone_number already exists") != -1)
          await this.resendOtp()
        else
          this.showMessage(error.response.data.status.toString().concat(' : ').concat(error.response.data.message), 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 1000);
      }
    },
    async registerClick() {
      try {
        this.showmessage_callback = null;
        if (this.username?.trim()=="" || this.username?.trim().length!=10) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_phoneno}`, 400);
          return;
        }
        if (this.otp?.trim()=="" || this.otp?.trim().length!=6) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_otp}`, 400);
          return;
        }

        this.isShowOverlay = true;
        const response = await api.confirmRegister(this.username, this.otp);
        // console.log(response);
        if (response.status==200) {
          this.showmessage_callback = this.onShowMessageCallBack;
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.register_success}`, 400);
        }
        else
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.register_notsuccess}`, 400);
      } catch (error) {
        this.showMessage(error.response.data.status.toString().concat(' : ').concat(error.response.data.message), 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 1000);
      }
    }
  },
};
</script>

<style scoped>
.text_input {
  background-color: #FAFAFA;
  color: #000000
}
.inputNumber >>> input[type='number'] {
  -moz-appearance:textfield;
}
.inputNumber >>> input::-webkit-outer-spin-button,
.inputNumber >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.link--text {
  padding: 5px;
  font-size: 0.8rem !important; 
  color: #FFFFFF;
  position: relative;
}

.input[type='checkbox'] {
  border-color: #ffffff;
}
input.largerCheckbox {
  transform: scale(2);
}

a {
  color: #ffc000;
  background-color: transparent;
  text-decoration: none;
}
</style>